<template>
  <div class="fixed-bottom" ref="fixedBottom" v-if="shouldShow">
    <i @click="back" class="el-icon-arrow-left" ></i>
    <i @click="go" class="el-icon-arrow-right" :class="{ disabled: currentPosition === currentLength - 1 }"></i>
  </div>
</template>
<script>
import { onMounted, ref, watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
const isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1; 
function isWxBrowser () {
  var ua = navigator.userAgent.toLowerCase();
  let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
  if(!isWx){
      return false;
  }else{
      let isWxWork = ua.match(/WxWork/i) == "wxwork";
      if(isWxWork){
          return false;
      }else{
          return true;
      }
  }
}
const browserFlag = isAndroid && isWxBrowser()
export default {
  setup() {
    const route = useRoute()
    const currentPosition = ref(0)
    const currentLength = ref(0)
    const sPosition = ref(0)
    const shouldShow = ref(false)
    const fixedBottom = ref(null)
    watch(route, () => {
      currentPosition.value = history.state.position
      currentLength.value = history.length
      console.log(history)
    })
    const html = document.querySelector('html')
    let body
    onMounted(() => {
      body = document.querySelector('.page')
    })
    window.addEventListener('scroll', () => {
      const position = sPosition.value
      const current = html.scrollTop
      shouldShow.value = position > current && browserFlag && currentLength.value !==  1
      sPosition.value = current
      if (shouldShow.value) {
        body.style.paddingBottom = fixedBottom.value.offsetHeight + 'px'
      } else {
        body.style.paddingBottom = ''
      }
    })
    return {
      currentPosition,
      currentLength,
      // shouldShow: isWxBrowser() && isAndroid,
      shouldShow,
      fixedBottom,
      back() {
        history.go(-1)
        setTimeout(() => {
          shouldShow.value = false
        }, 100)
      },
      go () {
        history.go(1)
        setTimeout(() => {
          shouldShow.value = false
        }, 100)
      }
    }
  },
}
</script>
<style scoped>
.el-icon-arrow-right.disabled {
  pointer-events: none;
  color: #ccc;
}
.fixed-bottom {
  position: fixed;
  z-index: 10000000000;
  bottom: 0;
  background: #fff;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 140px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-icon-arrow-left,.el-icon-arrow-right {
  font-size: 20px;
  padding: 10px;
  font-weight: bold;
}
</style>
