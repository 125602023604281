<template>
  <div class="page" @touchstart.stop>
    <nav class="container top">
      <div class="flex-nav">
        <span>
          <span class="welcome">传统文化学习平台&nbsp;&nbsp;欢迎您</span>
          <span v-if="!openMode">
            <el-dropdown placement="bottom" v-if="state.uname">
            <span class="uname">{{ state.uname }}</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span class="login-btn" v-else @click="router.push('/login')"
            >登&nbsp;录</span
          >
          </span>
          
        </span>
        <span> </span>
      </div>
      <div class="flex-nav">
        <div class="flex-nav">
          <img
            @click="route.name !== 'index' && router.push('/')"
            class="nav-img"
            src="./assets/pic/pt-img47.png"
            alt=""
          />
          <div class="flex1">
            <p>传统文化电子资源库</p>
            <p>权威国学教育资源数据库支持</p>
          </div>
        </div>
        <div class="flex1 center">
          <span
            @click="goPath(n)"
            v-for="n in state.nav"
            :class="state.current === n.key ? 'menu-item active' : 'menu-item'"
            :key="n.key"
            >{{ n.name }}</span
          >
        </div>
        <div class="flex-right">
          <el-select
            filterable
            remote
            multiple
            class="select"
            ref="searchInput"
            placeholder="请输入关键词"
            loading-text="检索中..."
            no-data-text="未找到"
            :remote-method="search"
            :loading="state.searchLoading"
          >
            <el-option
              v-for="item in state.searchResult"
              @click="goVideo(item)"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div @click="goSearch" class="search-btn">
            快速检索
          </div>
        </div>
      </div>
    </nav>

    <nav class="container mobile">
      <div class="mobile-top-info">
        <img
          @click="route.name !== 'index' && router.push('/')"
          class="nav-img"
          src="./assets/pic/pt-img47.png"
          alt=""
        />
        <span class="welcome">传统文化学习平台&nbsp;&nbsp;欢迎您</span>
      </div>
      <div class="mobile-search-info">
        <i class="s-icon el-icon-search"></i>
        <input v-model="state.key" class="mobile-input" type="text" />
        <span class="right-hand">
          <span @click="goSearch">搜索</span>
          <span>｜</span>
          <span v-if="!state.uname" @click="router.push('/login')">登录</span>
          <span v-else @click="showActionSheet">{{ state.uname }}</span>
        </span>
      </div>
      <div class="mobile-nav">
        <span
          @click="goPath(n)"
          v-for="n in state.nav"
          :class="state.current === n.key ? 'menu-item active' : 'menu-item'"
          :key="n.key"
          >{{ n.name }}</span
        >
      </div>
    </nav>

    <div style="position: relative">
      <div class="tab-bar">
        <div class="first-menu-bar mobile" @click="goNextMenu"><i class="el-icon-d-arrow-right"></i></div>
        <div
          class="container menu-wrapper"
          ref="menuRef"
          @mouseenter="enterMenu"
          @mouseleave="leaveMenu"
        >
          <span
            @click="goMedia(n.id)"
            v-for="(n, index) in state.menus"
            class="nav-item"
            :class="{ active: state.fid === n.id }"
            :key="n.key"
          >
            <span>{{ n.name }}</span>
            <div
              v-if="n.children"
              class="sub-menu"
              :style="'margin-left: -' + 6 * index + 'rem;'"
            >
              <span
                @click.stop="goMedia(n.id, m.id)"
                v-for="m in n.children"
                class="sub-nav-item"
                :class="{ active: state.sid === m.id }"
                :key="m.id"
                >{{ m.name }}</span
              >
            </div>
          </span>
        </div>
      </div>
      <div class="sub-menu-wrapper">
        <div class="mobile">
          <div class="sub-menu" :style="'margin-left: -' + 6 * index + 'rem;'">
            <span
              @click.stop="goMedia(state.fid, m.id)"
              v-for="m in (
                state.menus.find((i) => i.id === state.fid) || { children: [] }
              ).children"
              class="sub-nav-item"
              :class="{ active: state.sid === m.id }"
              :key="m.id"
              >{{ m.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <router-view
      :uname="state.uname"
      :menus="state.menus"
      :menuIdMap="state.menuIdMap"
      v-slot="{ Component }"
      class="inner"
    >
      <keep-alive include="medias">
        <component :prop="1" :is="Component">
          <template #footer>
            <Footer />
          </template>
        </component>
      </keep-alive>
    </router-view>
    <android-navigator />

  </div>
</template>

<script>
import {
  getCategory as _getCategory,
  getVideoList as _getVideoList,
} from "@api";
import { reactive, computed, watch, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Footer from "@c/Footer.vue";
import AndroidNavigator from "@c/androidNavigator.vue";

import "@/assets/js/rem.js";
const sortList = [
  "国文教育",
  "国艺素养",
  "国医养生",
  "国史人文",
  "大师说",
  "亲子早教",
  "民间艺术",
  "美食健康",
  "国学台读书会",
  "古籍典藏",
];
export default {
  name: "App",
  components: {
    Footer,
    AndroidNavigator
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const searchInput = ref(null);
    const menuRef = ref(null)
    const openMode = ref(process.env.VUE_APP_RUN_MODE === 'open');
    const state = reactive({
      userInfo: null,
      nav: [
        {
          key: "index",
          name: "首页",
        },
        {
          key: "videos",
          name: "视频",
        },
        {
          key: "audios",
          name: "音频",
        },
        {
          key: "document",
          name: "电子书",
        }
      ],
      current: route.name,
      key: "",
      searchResult: [],
      searchLoading: false,
      uname: localStorage.getItem("uname"),
      vmenuIdMap: {},
      vmenus: [],
      amenuIdMap: {},
      amenus: [],
      menuIdMap: {},
      menus: [],
      val: null,
      fid: null,
      sid: null,
      isMobile: window.innerWidth < 767
    });
    const lastData = ref({});
    const leaveData = ref({});
    const goPath = (path) => {
      if (route.name !== path.key || path.key === 'videos') {
        state.current = path.key
        if (path.key === 'document') {
          router.push('/videos/132095')
        } else {
          router.push("/" + path.key);
        }
      }
    };
    const goMedia = (fid, sid) => {
      // eslint-disable-next-line no-debugger
      if (route.name === "audios") {
        router.push("/audios/" + fid + "/" + sid);
      } else {
        router.push("/videos/" + fid + "/" + sid);
      }
    };
    const search = (key) => {
      state.key = key;
      if (key) {
        state.searchLoading = true;
        _getVideoList({
          key,
        })
          .then((res) => {
            state.searchResult = res.data.records;
          })
          .finally(() => {
            state.searchLoading = false;
          });
      } else {
        state.searchResult = [];
      }
    };

    const goSearch = () => {
      state.key && router.push(`/search/${state.key}`);
    };

    const goVideo = (item) => {
      searchInput.value.blur();
      state.key = "";
      router.push(
        `/${item.mediaType === "VIDEO" ? "video" : "audio"}/${item.id}`
      );
    };
    const logout = () => {
      localStorage.removeItem("uname");
      localStorage.removeItem("accessToken");
      state.uname = null;
      location.reload();
    };
    const getCategory = () => {
      const r1 = _getCategory({
        type: 0,
      }).then((res) => {
        console.log(res);
        const menus = [];
        const idMap = {};
        res.data.sort((a,b) => a.rank - b.rank).forEach((i) => {
          if (i.parentId === null) {
            menus.push(i);
          } else if(idMap[i.parentId]) {
            idMap[i.parentId].children = idMap[i.parentId].children || [];
            idMap[i.parentId].children.push(i);
          }
          idMap[i.id] = i;
        });
        state.vmenuIdMap = idMap;
        state.vmenus = menus.sort((a, b) => {
          const index1 = sortList.indexOf(a.name);
          const index2 = sortList.indexOf(b.name);
          if (index1 > -1 && index2 > -1) {
            return index1 - index2;
          } else {
            return index2;
          }
        });
      });
      const r2 = _getCategory({
        type: 1,
      }).then((res) => {
        console.log(res);
        const menus = [];
        const idMap = {};
        res.data.sort((a,b) => a.rank - b.rank).forEach((i) => {
          if (i.parentId === null) {
            menus.push(i);
          } else if(idMap[i.parentId]) {
            idMap[i.parentId].children = idMap[i.parentId].children || [];
            idMap[i.parentId].children.push(i);
          }
          idMap[i.id] = i;
        });
        state.amenuIdMap = idMap;
        state.amenus = menus.sort((a, b) => {
          const index1 = sortList.indexOf(a.name);
          const index2 = sortList.indexOf(b.name);
          return index1 - index2;
        });
      });

      Promise.all([r1, r2]).then(() => {
        if (route.name === "audios") {
          state.menuIdMap = state.amenuIdMap;
          state.menus = state.amenus;
        } else {
          state.menuIdMap = state.vmenuIdMap;
          state.menus = state.vmenus;
        }
        window.__menus = state.menus;
        window.menuDone && window.menuDone(state.menus);
      });
    };

    const shouldShowNav = computed(() => {
      return !!state.nav.find((i) => i.key === state.current);
    });

    const enterMenu = () => {
      if (state.isMobile) return
      lastData.value = {
        sid: state.sid,
        fid: state.fid,
      };
      state.sid = null;
      state.fid = null;
    };
    const leaveMenu = () => {
      if (state.isMobile) return
      state.sid = lastData.value.sid;
      state.fid = lastData.value.fid;
    };

    const goNextMenu = () => {
      menuRef.value.scrollLeft += menuRef.value.offsetWidth - 40
    }

    const showActionSheet = () => {
      window.weui.actionSheet(
        [
          {
            label: "退出登录",
            onClick: logout,
          }
        ],
        [
          {
            label: "取消",
            onClick: function() {
              console.log("取消");
            },
          },
        ],
        {
          className: "custom-classname",
          onClose: function() {
            console.log("关闭");
          },
        }
      );
    };

    getCategory();

    watch(route, (v) => {
      console.log(v.name, "000");
      state.uname = localStorage.getItem("uname");
      if (route.name === "audios") {
        state.menuIdMap = state.amenuIdMap;
        state.menus = state.amenus;
      } else {
        state.menuIdMap = state.vmenuIdMap;
        state.menus = state.vmenus;
      }
      if (state.menus.length) {
        window.__menus = state.menus;
        setTimeout(() => {
          // eslint-disable-next-line no-debugger
          // debugger
          window.menuDone && window.menuDone(state.menus);
        });
      }
      if (route.name !== "audios" && route.name !== "videos") {
        leaveData.value = {
          fid: state.fid,
          sid: state.sid
        };
        state.fid = null;
        state.sid = null;
      } else {
        state.sid = leaveData.value.sid;
        state.fid = leaveData.value.fid;
      }
    });

    window.setClass = (fid, sid) => {
      state.fid = fid;
      state.sid = sid;
      lastData.value = {
        sid,
        fid,
      };
    };

    onMounted(() => {
      console.log(searchInput.value);
      searchInput.value.$el
        .querySelector(".el-select__input")
        .addEventListener("keydown", (e) => {
          if (e.key.toUpperCase() === "ENTER" && state.key) {
            router.push(`/search/${state.key}`);
            searchInput.value.blur();
          }
        });
    });

    return {
      state,
      goPath,
      goMedia,
      search,
      goVideo,
      logout,
      route,
      router,
      shouldShowNav,
      enterMenu,
      leaveMenu,
      searchInput,
      goSearch,
      showActionSheet,
      goNextMenu,
      menuRef,
      openMode
    };
  },
};
</script>
<style lang="stylus">
.page {
  // height 100%
  font-size: 12px;
}

#app {
  height 100%
  // overflow-y: auto;
  // overflow-x: hidden;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%
}

.container {
  width: 1028px;
  margin: 0 auto;
  box-sizing: border-box;
}



.flex-nav {
  display: flex;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;
}
.welcome {
  font-size: 12px;
  font-family: '微软雅黑';
  font-weight: bold;
  color: #361905;
}
.login-btn{
  display inline-block
  width: 54px;
height: 17px;
line-height 17px
background: #000000;
border-radius: 9px;
font-size: 12px;
font-family: '微软雅黑';
color: #FEFEFE;
text-align center
margin-left 18px
cursor pointer
}
.search-btn {
  position: absolute;
  right 0
  top 0
  height: 23px;
  line-height: 23px;
  color: #fff;
  width: 100px;
  text-align: center;
  background-image: linear-gradient(#be122c, #770d16);
  border-radius 0 12px 12px 0;
  padding-right: 15px;
  cursor pointer;
  z-index: 1
  &:after {
    content: '';
    position absolute;
    height: 23px;
    width: 23px;
    right: 15px;
    top: 0;
    background-image:url('./assets/pic/Search.png');
    background-size 14px;
    background-repeat no-repeat;
    background-position center;
  }
  &:hover {
    background-image: linear-gradient(#770d16, #be122c);
  }
}


.flex1 {
  flex: 1;
  font-family Microsoft YaHei
  font-size 10px
}
.center {
  text-align center
}
.flex-nav .menu-item {
  font-family '微软雅黑'
  font-size 24px
  cursor pointer
  &.active, &:hover {
    color: #cc1c1a;
  }
}
.flex-nav .menu-item + .menu-item {
  margin-left 20px
}

.sub-menu {
  position absolute;
  // top 48px;
  padding-bottom: 2rem;
  white-space: nowrap;
  display none
  margin-top: 5px;
  min-width: 300px;
  align-items: flex-end;
    // height: 23px;
    box-sizing: border-box;
  &>span{
    padding 0 5px;
    font-family '微软雅黑'
    font-weight bold
    font-size 14px
    color: #F8F9E1;
    &:not(:last-of-type) {
      &::after {
        content: '|';
        color: #f8f9e1;
        margin-left: 10px
      }
    }
  }

}
.sub-menu-wrapper {
  height 23px
  background #52504e
}


.flex-right {
  width: 260px;
  text-align: right;
  position relative;
}


.nav-img {
  height: 40px;
  margin-right: 10px;
}


.search-input {
  width: 200px;
  height: 22px;
  line-height: 22px;
  border-radius: 11px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 0px 10px;

  &:focus {
    outline: none;
  }
}

.select {
  width 100%
  height 23px
  line-height 23px
}

.tab-bar {
  background-image: url('./assets/pic/pt-ico5.jpeg');
  padding 5px 0;
}

.inner {
  flex: 1;
  position: relative;
  padding-bottom: 240px;
  height: auto;
}


footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 240px;
  padding: 20px 0;
  box-sizing: border-box;
}


.menu-wrapper {
  // display flex;
  // justify-content space-between
}

.nav-item {
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  // padding: 0 22px;
  position: relative;
  z-index: 1;
  color: #fff;
  font-size 18px;
  font-family: '微软雅黑';
  color: #F8F9E1;

  & + .nav-item {
    margin-left: 30px;
  }
  &:hover .sub-menu, &.active .sub-menu{
    display flex
  }
}


.sub-nav-item:hover,.sub-nav-item.active {
  color: #cc1c1a
}


.nav-item.active,.nav-item:hover {
  color: #c47800;

  &:before, &:after {
    content: '';
    background-image: url('./assets/pic/pt-ico4.png');
    width: calc(50% + 20px);
    display: inline-block;
    position: absolute;
    height: 100%;
    z-index: -1;
    background-size: cover;
  }

  &:before {
    left: -20px;
  }

  &:after {
    right: -20px;
    background-position: right;
  }
}

@media (any-hover: none) {

  .sub-nav-item:hover {
    color: #f8f9e1
  }

.nav-item:hover {
  color: #f8f9e1
  &:before, &:after {
    display: none;
  }
}
.sub-nav-item.active {
  color: #cc1c1a
}

  .nav-item.active {
  color: #c47800;

  &:before, &:after {
    content: '';
    background-image: url('./assets/pic/pt-ico4.png');
    width: calc(50% + 20px);
    display: inline-block;
    position: absolute;
    height: 100%;
    z-index: -1;
    background-size: cover;
  }

  &:before {
    left: -20px;
  }

  &:after {
    right: -20px;
    background-position: right;
  }
}
  
}

.uname {
  cursor: pointer;
  margin-left 10px
}

.video-container {
  cursor: pointer;
}
.container.mobile {
  display: none;
}
@media screen and (max-width: 1050px) {
  .container {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 770px) {
  .flex-nav .menu-item {
    font-size: 20px;
    &.active {
      color: #cc1c1a;
    }
  }
  .flex-nav .menu-item + .menu-item {
    margin-left 10px
  }
  .flex-right {
    width 260px
  }
  .nav-item {
    font-size 12px
  }
  .sub-menu>span {
    font-size 12px
  }
  footer {
    height: 210px;
  }
  .inner {
    padding-bottom: 210px;
  }
}

@media screen and (max-width: 767px) {
  .menu-item {
    &.active {
      color: #cc1c1a;
    }
  }
  .container.top {
    display: none;
  }
  .container.mobile {
    display: block;
    width: calc(100% - 40px)
  }
  .nav-img {
    height: 30px;
  }
  .mobile-top-info {
    display: flex;
    justify-content space-between;
    align-items: center;
    width: 340px;
    margin: 20px auto 10px auto;
  }
  .mobile-search-info {
    display: flex;
    justify-content space-between;
    align-items: center;
    margin: 10px auto 10px auto;
    position relative
  }
  .mobile-nav {
    display: flex;
    justify-content space-between;
    align-items: center;
    font-size 26px;
    font-family: '微软雅黑';
    font-weight: bold;
    color: #381905;
    line-height: 60px
  }
  .menu-wrapper {
    white-space: nowrap;
    width: 100%;
    overflow-y: hidden;
    padding: 10px 20px;
  }
  .tab-bar {
    overflow auto
  }
  .nav-item {
    font-size 21px;
    line-height 28px;
    font-family: '微软雅黑';
    font-weight: bold;
    height: 28px;
    & > span {
      display inline-block
    }
  }
  .tab-bar {
    padding: 0;
  }
  .sub-menu {
    position static;
    display block;
    margin-top 0;
    line-height: 40px
    height: 40px
    overflow-y: hidden;
    padding: 0 17px;
  }
  .sub-menu-wrapper {
    height: 40px
    overflow: auto;
  }
  .sub-menu>span {
    font-size 17px
  }
  .sub-menu .sub-nav-item {
    min-width 60px;
    display inline-block
    text-align center
    position relative
    padding 0 10px
    &::after {
      position absolute;
      right 0
    }
  }
  .menu-wrapper .sub-menu {
    display: none!important;
  }
  .container {
    width: calc(100% - 20px);
  }
  footer {
    height: calc(100vw * 0.4933);
    background-image: url('./assets/pic/mobile-bottom.png');
    background-size: contain;
  }
  .inner {
    padding-bottom: calc(100vw * 0.4933);
  }
  .mobile-input {
    flex: 1;
    height: 30px;
    line-height: 30px;
    border-radius: 13px;
    border: none;
    padding-left 35px
    &:focus-visible, &:focus {
      outline: none;
    }
  }
  .s-icon {
    position absolute;
    top 0;
    bottom: 0;
    margin: auto;
    height: 20px;
    font-size 16px;
    line-height 20px!important;
    width: 35px;
    text-align center;
    color: #aaa
  }
  .right-hand {
    width: 100px;
    display: flex;
    justify-content space-between;
    padding-left: 20px;
    font-family: '微软雅黑';
    font-size 18px;
    line-height: 14px;
    white-space: nowrap;
  }
  .tab-bar {
    height: 48px;
  }
  .menu-wrapper {
    padding-right 80px
    scroll-behavior smooth
  }
  .first-menu-bar {
    position: absolute;
    right: 0;
    height: 48px;
    line-height: 48px;
    width: 70px;
    text-align: right;
    background: linear-gradient( 90deg , rgba(183,5,4,0), #b70504, #b70504);
    z-index: 1000;
    padding-right: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #f8f9e1;
  }
  .first-menu-bar .el-icon-d-arrow-right {
    font-size: 16px;
    color: #f8f9e1;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
